import React from "react"
import LiteYouTubeEmbed from "react-lite-youtube-embed"
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css"
import "./style.scss"

const Video = ({ videoSrcURL, videoTitle, videoCode, ...props }) => {
  return (
    <div className="video">
      {/* <iframe
        src={videoSrcURL + `?controls=0&modestbranding=1rel=0&iv_load_policy=3`}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        className="video"
      /> */}
      <LiteYouTubeEmbed id={videoCode} title={videoTitle} />
    </div>
  )
}
export default Video
