import React from "react"
import { RichText } from "prismic-reactjs"
import curve1 from "../../../assets/curves/curve-top-light-01.png"
import curve2 from "../../../assets/curves/curve-bottom-dark-02.png"
import "./style.scss"

export default function pestControl2({ data }) {
  return (
    <div
      className="home-pestControl2"
      style={{
        backgroundImage: `linear-gradient(rgba(240, 241, 242, 0.8), rgba(251, 251, 252, 0.8), rgba(246, 246, 248, 0.8), rgba(251, 251, 252, 0.8), rgba(244, 246, 249, 0.8)), url(${
          data.primary.bg_image ? data.primary.bg_image.url : null
        })`,
        backgroundBlendMode: "hard-light",
        backgroundPosition: "center",
      }}
    >
      <img
        src={curve1}
        style={{
          width: "100%",
          marginTop: "-6px",
        }}
        alt=""
      />
      <div className="home-pestControl2-container">
        <h2> {RichText.asText(data.primary.title.raw)}</h2>
        {data.items.map((info, index) => {
          return <p key={index}>{RichText.asText(info.content.raw)}</p>
        })}
      </div>

      <img
        src={curve2}
        style={{
          width: "100%",
          marginBottom: "-6px",
        }}
        alt=""
      />
    </div>
  )
}
