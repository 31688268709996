import React from "react"
import divider2 from "../../../assets/home/divider2.png"
import curve from "../../../assets/curves/curve-bottom-light-02.png"
import { RichText } from "prismic-reactjs"
import "./style.scss"

export default function serviceTeam({ data }) {
  return (
    <div
      className="home-service-team"
      style={{
        backgroundImage: ` linear-gradient(#333333, #1E1D18), url(${
          data.primary.bg_image ? data.primary.bg_image.url : null
        })`,
        backgroundBlendMode: "multiply",
      }}
    >
      <img
        src={divider2}
        style={{
          width: "100%",
          marginTop: "-6px",
        }}
        alt=""
      />
      <div className="home-service-team-container">
        <h2>{RichText.asText(data.primary.title.raw)}</h2>
        <div className="home-service-team-images">
          {data.items.slice(0, 5).map((info, index) => {
            return (
              <div>
                <img key={index} src={info.team_image.url} alt="services" />
                <h4>{info.member_name.text}</h4>
                <h5>{info.member_title.text}</h5>
              </div>
            )
          })}
        </div>
        <RichText
          render={data.primary.description.raw}
          className="home-service-team-description"
        />
      </div>
      <img
        src={curve}
        style={{
          width: "100%",
          marginBottom: "-6px",
        }}
        alt=""
      />
    </div>
  )
}
