import React from "react"
import { Button } from "@react-md/button"
import { RichText } from "prismic-reactjs"
import stone from "../../../assets/layout/stone.svg"
import Video from "./video"
import { Link } from "gatsby"
import "./style.scss"

export default function whoAreWe({ data }) {
  const formattedUrl = data.youtube_embed_link
    ? data.youtube_embed_link.embed_url
      ? data.youtube_embed_link.embed_url.replace("watch?v=", "embed/")
      : ""
    : ""
  const videoCode = data.youtube_embed_link
    ? data.youtube_embed_link.embed_url
      ? data.youtube_embed_link.embed_url.split("?v=")[1]
      : ""
    : ""

  return (
    <div className="home-whoAreWe">
      <div className="home-whoAreWe-container">
        <Video
          videoSrcURL={formattedUrl}
          videoCode={videoCode}
          videoTitle={
            data.youtube_embed_link
              ? data.youtube_embed_link.title
                ? data.youtube_embed_link.title
                : ""
              : ""
          }
        />
        <div className="home-whoAreWe-container-content">
          <h4>{RichText.asText(data.subtitle.raw)}</h4>
          <h2>
            <img src={stone} alt="stone" className="whoAreWe-control-stone" />
            {RichText.asText(data.title.raw)}
          </h2>

          <p>{RichText.asText(data.description.raw)}</p>
          <div className="btn-container">
            <Link to="/about-us">
              <Button themeType="contained" className="btn">
                MORE ABOUT SSPEST
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
